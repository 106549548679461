import { useState, useCallback } from "react";

import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import "./Team.css";
import NavBar from "../components/NavBar";

const Team = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  return (
    <>
      <div className="team">
     <NavBar/>
        <div className="taglinebody4">
          <div className="taglinetextgroupthinklab4">
            <div className="taglineheadingblock5">
              <div className="ceoheading-block-text">
                <b className="taglineheadingblocktext4">The Thinklab Family</b>
              </div>
              <div className="ceoparagraph-block">
                <div className="taglineparagraph-blocktext">
                  United by Passion, Driven by Innovation.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-team-section-container">
          <div className="subtext-group-thinklab2">
            <div className="subheading-block-text3">
              <b className="subheading-block-text4">Meet Our Team</b>
            </div>
            <div className="subparagraph-block2">
              <div className="taglineparagraph-blocktext">
                Welcome to The Thinklab Family! We're thrilled to introduce you
                to the talented individuals who make up our team. At Thinklab,
                we believe in the power of collaboration, creativity, and
                innovation. Together, we're united by a shared passion for
                pushing boundaries, solving challenges, and driving positive
                change in everything we do. Get to know the faces behind our
                projects, and discover the spirit of camaraderie and dedication
                that defines us. We're excited to share our journey with you!
              </div>
            </div>
          </div>
          <div className="our-team-members-cards-block-s">
            <div className="ceo-section">
              <div className="ceo-card-block">
                <div className="ceoheading-block-text">
                  <b className="ceoheading-block-text1">CEO</b>
                </div>
                <div className="ceoour-team-card">
                  <img
                    className="ceoourteam-img"
                    alt=""
                    src="/ceoourteam-img@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ceotext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ceoheading-block-text3">
                          Dr Said Alkali Kori
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ceoparagraph-block-text">Chairman</div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon6"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon6"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon6"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon24">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        Dr. Sa’id is a versatile professional with a background
                        in architecture and a passion for development finance.
                        With experience as both a licensed researcher and a
                        project manager, He brings a unique blend of skills to
                        Thinklab Group. His expertise lies in strategic
                        planning, financial analysis, and project execution,
                        making him a driving force behind our innovative and
                        sustainable development projects.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="paragraph-block-text19">
                <div className="lorem-ipsum-dolor-container5">
                  <p className="welcome-to-thinklab-group">
                    <b className="welcome-to-thinklab">
                      Welcome to Thinklab Group!
                    </b>
                    <span className="span">{` `}</span>
                  </p>
                  <p className="blank-line">&nbsp;</p>
                  <p className="blank-line">
                    As the Chief Executive Officer, it's my pleasure to extend a
                    warm welcome to you. Our team is dedicated to excellence,
                    innovation, and collaboration, and we're thrilled to have
                    you explore our organization. At Thinklab Group, we believe
                    in pushing boundaries, embracing challenges, and striving
                    for continuous improvement. Together, we're committed to
                    making a positive impact through our work and contributing
                    to the success of our clients, partners, and communities.
                    Thank you for your interest in Thinklab Group. I invite you
                    to learn more about our team, our values, and the exciting
                    opportunities that lie ahead. Together, let's embark on a
                    journey of growth, success, and innovation.
                  </p>
                  <p className="blank-line">Best regards.</p>
                </div>
              </div>
            </div>
            <div className="leadership-team-card-block">
              <div className="ceoheading-block-text">
                <b className="ceoheading-block-text1">Leadership Team</b>
              </div>
              <div className="our-team-cards-block">
                <div className="our-team-card">
                  <img
                    className="our-team-img-container1"
                    alt=""
                    src="/our-team-img-container1@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ceoheading-block-text3">
                          Dr Babagana Adam
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourteamparagraph-block-text">
                          Group Managing Director (GMD)
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="ourteamparagraphdrop-block-tex">
                      <div className="ourparagraph-block-text">
                        Dr. Babagana is an accomplished professional with a
                        strong background in collaborating with government,
                        non-governmental organizations (NGOs), and the higher
                        education sector. He holds a PhD in Epidemiology from
                        the University of Salford Manchester UK, showcasing his
                        expertise in research and critical analysis. His
                        leadership skills and strategic vision have been pivotal
                        in driving growth and fostering partnerships at Thinklab
                        Group.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container2@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Babagana Kolomi
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Chief Of Staff
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container3@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Veronica Braimoh
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Group Head of Cooperate Service (GHCS)
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        As the Head of Corporate Services at Thinklab Group,
                        Veronica is a seasoned professional with a wealth of
                        experience in managing diverse functions within the
                        organization.  She brings a strategic mindset and a
                        proven track record of optimizing operational efficiency
                        and driving organizational success. Her leadership and
                        expertise play a crucial role in ensuring smooth and
                        effective corporate operations, and supporting the
                        overall mission and goals of Thinklab Group.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container4@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Ahmad Tijjani Adaba
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Group Head of Account (GHA)
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        As the Head of Corporate Services at Thinklab Group,
                        Veronica is a seasoned professional with a wealth of
                        experience in managing diverse functions within the
                        organization.  She brings a strategic mindset and a
                        proven track record of optimizing operational efficiency
                        and driving organizational success. Her leadership and
                        expertise play a crucial role in ensuring smooth and
                        effective corporate operations, and supporting the
                        overall mission and goals of Thinklab Group.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container5@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Muhammad Ismail
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Group Head Of ICT
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container6@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">{`Adetola Olatunji `}</b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Legal
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container7@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">{`Olufemi John `}</b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Communications
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container8"
                    alt=""
                    src="/our-team-img-container8@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Aliyu Muhammad
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Technical
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container9@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">{`Ifeanyi Chu `}</b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Design
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container10@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Abubakar Muazu
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Audit
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container11@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Fauziyya Nasir
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">{`Public Relations `}</div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-team-card">
                  <img
                    className="our-team-img-container2"
                    alt=""
                    src="/our-team-img-container12@2x.png"
                  />
                  <div className="ceoright-container">
                    <div className="ourteamtext-group-thinklab">
                      <div className="ceoheading-block-text">
                        <b className="ourteamheading-blocktext">
                          Aisha Modu Sheriff
                        </b>
                      </div>
                      <div className="ceoparagraph-block">
                        <div className="ourparagraph-block-text">
                          Head Of Procurement and Suply Chain
                        </div>
                      </div>
                    </div>
                    <div className="ceo-socials">
                      <div className="social-link-footer">
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/twitter-logo2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/instagram-logosvg2.svg"
                        />
                        <img
                          className="twitter-logo-icon7"
                          alt=""
                          src="/facebook-symbolsvg2.svg"
                        />
                      </div>
                      <div className="dropdown-icon25">
                        <img
                          className="stat-minus-1-icon22"
                          alt=""
                          src="/stat-minus-1.svg"
                        />
                      </div>
                    </div>
                    <div className="paragraph-block-text18">
                      <div className="ourparagraph-block-text">
                        <p className="welcome-to-thinklab-group">
                          Lorem ipsum dolor sit amet consectetur. Nunc facilisi
                          turpis sit fringilla. Tempor id donec dolor malesuada
                          pretium convallis. Elementum amet nascetur facilisi id
                          eu. Tincidunt nullam odio turpis habitant urna
                          curabitur malesuada. Suscipit porttitor dui a eu diam.
                          In porta feugiat velit ut tincidunt pellentesque leo
                          turpis erat. Nisl pellentesque.
                        </p>
                        <p className="welcome-to-thinklab-group">&nbsp;</p>
                        <p className="welcome-to-thinklab-group">
                          Elementum amet nascetur facilisi id eu. Tincidunt
                          nullam odio turpis habitant urna curabitur malesuada.
                          Suscipit porttitor dui a eu diam. In porta feugiat
                          velit ut tincidunt pellentesque leo turpis erat. Nisl
                          pellentesque.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourteamjointeam-section">
          <div className="ourteamjointeamheading-block">
            <b className="ourteamjointeamheading-block-t">Join Our Team</b>
          </div>
          <div className="ourteamjointeam-section1">
            <div className="ourteamjointeamheading-block1">
              <div className="ourteamjointeamblockbody">
                <div className="heading-block-text43">
                  <b className="heading-block-text44">Heading Block Text</b>
                </div>
                <div className="subparagraph-block2">
                  <div className="ourparagraph-block-text">
                    At Thinklab Group, we believe in the power of people and
                    ideas to shape a better future. We offer a dynamic and
                    supportive environment where you can grow your skills,
                    collaborate with passionate colleagues, and make a lasting
                    impact on the world around you. Join us and be part of
                    something extraordinary
                  </div>
                </div>
              </div>
              <div className="primary-button3">
                <b className="button16">Explore A Career With Thinklab</b>
              </div>
            </div>
            <img
              className="join-our-team-img"
              alt=""
              src="/join-our-team-img@2x.png"
            />
          </div>
        </div>
        <div className="ourteamjointeam-section2">
          <div className="joinourteambodyheader">
            <b className="joinourteambodyheadertext">Join Our Team</b>
          </div>
          <div className="ourteamjointeam-about-us">
            <div className="ourteamjointeamblock">
              <div className="ourteamjointeamblockbody">
                <div className="ceoheading-block-text">
                  <b className="ourteamjointeamblockheadertext">About Us</b>
                </div>
                <div className="subparagraph-block2">
                  <div className="aboutusjointheteambodytet1">
                    At Thinklab Group, we believe in the power of people and
                    ideas to shape a better future. We offer a dynamic and
                    supportive environment where you can grow your skills,
                    collaborate with passionate colleagues, and make a lasting
                    impact on the world around you. Join us and be part of
                    something extraordinary.
                  </div>
                </div>
              </div>
              <button className="careerteambutton1">
                <b className="careerteambuttontext1">
                  Explore A Career With Thinklab
                </b>
              </button>
            </div>
            <img
              className="ourteamjointeamblockimg-icon"
              alt=""
              src="/ourteamjointeamblockimg@2x.png"
            />
          </div>
        </div>
        <div className="footer-section-thinklab5">
          <div className="footer-upper-container5">
            <div className="logotaglinesocial-links-cont5">
              <div className="footer-logo-and-tagline-contai5">
                <img
                  className="thinklab-logo-icon11"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text21">
                  <div className="ourparagraph-block-text">
                    <p className="welcome-to-thinklab-group">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="welcome-to-thinklab-group">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-footer">
                <img
                  className="twitter-logo-icon7"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon7"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon7"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link5">
              <div className="ceoheading-block-text">
                <div className="text-link40">Our Company</div>
              </div>
              <div className="ceoheading-block-text">
                <div className="text-link40">Our Services</div>
              </div>
              <div className="ceoheading-block-text">
                <div className="text-link40">Our Projects</div>
              </div>
              <div className="ceoheading-block-text">
                <div className="text-link40">{`News & Insights`}</div>
              </div>
              <div className="ceoheading-block-text">
                <div className="text-link40">Careers</div>
              </div>
              <div className="ceoheading-block-text">
                <div className="text-link40">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved5">
            <div className="copyright-parent3">
              <img className="twitter-logo-icon7" alt="" src="/copyright.svg" />
              <div className="ceoheading-block-text">
                <div className="div15">2024</div>
              </div>
            </div>
            <div className="div15">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Team;
