import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./TabletMenu.css";

const TabletMenu = ({ className = "", onClose }) => {
  const navigate = useNavigate();

  const onThinklabLogoImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/company");
  }, [navigate]);

  const onOurTeamClick = useCallback(() => {
    navigate("/team");
  }, [navigate]);

  const onOurPartnersTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='ourPartnersText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start" });
    }
  }, []);

  const onContactUsTextClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onPrimaryNavLinkDropdownCont2Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onPrimaryButtonContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={`tablet-menu ${className}`}>
      <div className="frame-parent">
        <div className="header-tabletmobile-container">
          <div className="header-tabletmobile2">
            <img
              className="thinklab-logo-icon4"
              alt=""
              src="/thinklab-logo1@2x.png"
              onClick={onThinklabLogoImageClick}
            />
            <img className="cancel-icon2" alt="" src="/cancel-icon.svg" onClick={onClose}/>
          </div>
          <div className="primary-nav-link-dropdown-cont6">
            <div className="overlat-text-link-parent3">
              <div className="overlat-text-link78">
                <b className="news-insights">Our Company</b>
              </div>
              <div className="dropdown-icon4">
                <img
                  className="stat-minus-1-icon4"
                  alt=""
                  src="/stat-minus-1.svg"
                />
              </div>
            </div>
            <div className="links-container6">
              <div className="our-company-links-container6">
                <div className="heading-block-text37">
                  <b className="heading-block-text38">Who We Are</b>
                </div>
                <div className="overlat-text-link79">
                  <b className="text-link89">Our Company</b>
                </div>
                <div className="secondary-links-group14">
                  <button className="about-us" onClick={onAboutUsClick}>
                    About Us
                  </button>
                  <button className="about-us" onClick={onOurTeamClick}>
                    Our Team
                  </button>
                  <div
                    className="our-partners"
                    data-scroll-to="ourPartnersText"
                    onClick={onOurPartnersTextClick}
                  >
                    Our Partners
                  </div>
                  <div className="contact-us1" onClick={onContactUsTextClick}>
                    Contact Us
                  </div>
                </div>
              </div>
              <div className="our-culture-links-container8">
                <div className="heading-block-text37">
                  <b className="heading-block-text38">Our Culture</b>
                </div>
                <div className="overlat-text-link79">
                  <b className="text-link89">Our Culture</b>
                </div>
                <div className="secondary-links-group15">
                  <div className="our-esg-strategy">Our ESG Strategy</div>
                  <div className="our-esg-strategy">{`Community & Citizenship`}</div>
                  <div className="our-esg-strategy">{`Diversity, Equity & Inclusion`}</div>
                  <div className="our-esg-strategy">{`Environmental Sustainability `}</div>
                  <div className="our-esg-strategy">Innovation</div>
                  <div className="our-esg-strategy">{`Safety & Wellness`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="primary-nav-link-dropdown-cont6">
            <div className="overlat-text-link-parent3">
              <div className="overlat-text-link78">
                <b className="our-services">Our Services</b>
              </div>
              <div className="dropdown-icon4">
                <img
                  className="stat-minus-1-icon4"
                  alt=""
                  src="/stat-minus-1.svg"
                />
              </div>
            </div>
            <div className="links-container6">
              <div className="our-company-links-container7">
                <div className="heading-block-text37">
                  <b className="heading-block-text38">Strategy</b>
                </div>
                <div className="overlat-text-link79">
                  <b className="text-link89">Our Company</b>
                </div>
                <div className="secondary-links-group14">
                  <div className="our-esg-strategy">Innovation</div>
                  <div className="our-esg-strategy">Development</div>
                  <div className="our-esg-strategy">Finance</div>
                  
                </div>
              </div>
              <div className="our-culture-links-container8">
                <div className="heading-block-text37">
                  <b className="heading-block-text38">Expertise</b>
                </div>
                <div className="overlat-text-link79">
                  <b className="text-link89">Our Culture</b>
                </div>
                <div className="secondary-links-group17">
                  <div className="our-esg-strategy">Engineering Service</div>
                  <div className="our-esg-strategy">Road Construction</div>
                  <div className="our-esg-strategy">Project Management</div>
                  <div className="our-esg-strategy">Mega City Development</div>
                  <div className="our-esg-strategy">Value Engineering</div>
                  <div className="our-esg-strategy">{`Insurance & Safety`}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="primary-nav-link-dropdown-cont8"
            onClick={onPrimaryNavLinkDropdownCont2Click}
          >
            <div className="overlat-text-link-parent3">
              <div className="overlat-text-link78">
                <b className="our-services">Our Projects</b>
              </div>
            </div>
          </div>
          <div className="primary-nav-link-dropdown-cont6">
            <div className="overlat-text-link-parent3">
              <div className="overlat-text-link78">
                <b className="news-insights">{`News & Insights`}</b>
              </div>
              <div className="dropdown-icon4">
              <img
                className="dropdown-icon12"
                alt=""
                src="/dropdown-icon@2x.png"
              />
                </div>
            </div>
            <div className="our-company-links-container8">
              <div className="heading-block-text37">
                <b className="heading-block-text38">
                  Featured News and Isights
                </b>
              </div>
              <div className="overlat-text-link79">
                <b className="text-link89">Our Company</b>
              </div>
              <div className="secondary-links-group15">
                <div className="thinklab-charity-foundation">
                  Thinklab Charity Foundation announces full tuition scholarship
                  for top jamb scorers
                </div>
                <div className="thinklab-charity-foundation">
                  Niger State Government Partners with Thinklab Group to Train
                  200 Youths in Bread Baking
                </div>
                <div className="thinklab-charity-foundation">
                  Thinklab Group Partners as Platinum Sponsor of 2024 Africa
                  International Housing Show
                </div>
              </div>
            </div>
          </div>
          <div className="primary-nav-link-dropdown-cont6">
            <div className="overlat-text-link-parent3">
              <div className="overlat-text-link78">
                <b className="news-insights">Careers</b>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-button-container2">
          <div className="primary-button-frame">
            <div
              className="primary-button3"
              onClick={onPrimaryButtonContainerClick}
            >
              <b className="button3">Contact Us</b>
            </div>
          </div>
          <div className="social-links2">
            <div className="overlat-text-link88">
              <div className="text-link94">Twitter</div>
            </div>
            <div className="overlat-text-link88">
              <div className="text-link94">Instagram</div>
            </div>
            <div className="overlat-text-link88">
              <div className="text-link94">Facebook</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TabletMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default TabletMenu;
