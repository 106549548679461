import { useCallback } from "react";
import "./TabletmobileOverlayMenu.css";

const TabletmobileOverlayMenu = ({ onClose }) => {
  const onThinklabLogoImageClick = useCallback(() => {
    // Please sync "Homepage Hi-fi tablet design" to the project
  }, []);

  const onFrameContainerClick = useCallback(() => {
    // Please sync "Our Company Hi-fi tablet design" to the project
  }, []);

  const onPrimaryNavLinkDropdownCont1Click = useCallback(() => {
    // Please sync "Our Services Hi-fi tablet design" to the project
  }, []);

  const onPrimaryNavLinkContainerClick = useCallback(() => {
    // Please sync "Our Project Hi-fi tablet design" to the project
  }, []);

  const onFrameContainer2Click = useCallback(() => {
    // Please sync "News & Insight Hi-fi tablet design" to the project
  }, []);

  const onOurCompanyLinksContainerClick = useCallback(() => {
    // Please sync "Individual News or Insight Hi-fi tablet design" to the project
  }, []);

  const onPrimaryNavLinkContainer1Click = useCallback(() => {
    // Please sync "Career page Hi-fi tablet design" to the project
  }, []);

  return (
    <div className="tabletmobile-overlay-menu">
      <div className="header-tabletmobile-parent">
        <div className="header-tabletmobile">
          <img
            className="thinklab-logo-icon12"
            alt=""
            src="/thinklab-logo1@2x.png"
            onClick={onThinklabLogoImageClick}
          />
          <img className="cancel-icon" alt="" src="/cancel-icon.svg" />
        </div>
        <div className="nav-links-tabletmobile">
          <div className="primary-nav-link-dropdown-cont">
            <div
              className="overlat-text-link-parent"
              onClick={onFrameContainerClick}
            >
              <div className="overlat-text-link24">
                <b className="text-link70">Our Company</b>
              </div>
              <div className="dropdown-icon37">
                <img
                  className="stat-minus-1-icon39"
                  alt=""
                  src="/stat-minus-1.svg"
                />
              </div>
            </div>
            <div className="links-container">
              <div className="our-company-links-container2">
                <div className="heading-block-text57">
                  <b className="heading-block-text58">Who We Are</b>
                </div>
                <div className="overlat-text-link25">
                  <b className="text-link71">Our Company</b>
                </div>
                <div className="secondary-links-group4">
                  <div className="overlat-text-link26">
                    <div className="text-link72">About Us</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Our Team</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Our Partners</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Contact Us</div>
                  </div>
                </div>
              </div>
              <div className="our-company-links-container2">
                <div className="heading-block-text57">
                  <b className="heading-block-text58">Our Culture</b>
                </div>
                <div className="overlat-text-link25">
                  <b className="text-link71">Our Culture</b>
                </div>
                <div className="secondary-links-group4">
                  <div className="overlat-text-link26">
                    <div className="text-link72">Our ESG Strategy</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Community & Citizenship`}</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Diversity, Equity & Inclusion`}</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Environmental Sustainability `}</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Innovation</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Safety & Wellness`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="primary-nav-link-container"
            onClick={onPrimaryNavLinkDropdownCont1Click}
          >
            <div className="overlat-text-link-group">
              <div className="overlat-text-link24">
                <b className="text-link70">Our Services</b>
              </div>
              <div className="dropdown-icon37">
                <img
                  className="stat-minus-1-icon39"
                  alt=""
                  src="/stat-minus-1.svg"
                />
              </div>
            </div>
            <div className="links-container1">
              <div className="our-company-links-container3">
                <div className="heading-block-text57">
                  <b className="heading-block-text58">Strategy</b>
                </div>
                <div className="overlat-text-link38">
                  <b className="text-link84">Strategy</b>
                </div>
                <div className="secondary-links-group6">
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Design & Architecture`}</div>
                  </div>
                  <div className="overlat-text-link40">
                    <div className="text-link86">Pre construction</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Mega City Development</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Mass Housing Development</div>
                  </div>
                </div>
              </div>
              <div className="our-company-links-container3">
                <div className="heading-block-text57">
                  <b className="heading-block-text58">Expertise</b>
                </div>
                <div className="overlat-text-link25">
                  <b className="text-link71">Expertise</b>
                </div>
                <div className="secondary-links-group6">
                  <div className="overlat-text-link26">
                    <div className="text-link72">Engineering Service</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Road Construction</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Project Management</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Risk Management</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">{`Insurance & Safety`}</div>
                  </div>
                  <div className="overlat-text-link26">
                    <div className="text-link72">Energy Service</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="primary-nav-link-container"
            onClick={onPrimaryNavLinkContainerClick}
          >
            <div className="overlat-text-link-wrapper">
              <div className="overlat-text-link24">
                <b className="text-link70">Our Projects</b>
              </div>
            </div>
          </div>
          <div className="primary-nav-link-dropdown-cont2">
            <div
              className="overlat-text-link-container"
              onClick={onFrameContainer2Click}
            >
              <div className="overlat-text-link24">
                <b className="text-link70">{`News & Insights`}</b>
              </div>
              <div className="dropdown-icon37">
                <img
                  className="stat-minus-1-icon39"
                  alt=""
                  src="/stat-minus-1.svg"
                />
              </div>
            </div>
            <div className="heading-block-text-parent">
              <div className="heading-block-text65">
                <b className="heading-block-text58">{`Featured News & Insight`}</b>
              </div>
              <div className="links-container2">
                <div
                  className="our-company-links-container4"
                  onClick={onOurCompanyLinksContainerClick}
                >
                  <div className="overlat-text-link52">
                    <b className="text-link84">Strategy</b>
                  </div>
                  <div className="secondary-links-group6">
                    <div className="overlat-text-link26">
                      <div className="text-link72">
                        The Future of Sustainable Construction: Innovations and
                        Best Practices
                      </div>
                    </div>
                    <div className="overlat-text-link40">
                      <div className="text-link86">Pre construction</div>
                    </div>
                  </div>
                </div>
                <div className="our-culture-links-container4">
                  <div className="overlat-text-link40">
                    <b className="text-link71">Expertise</b>
                  </div>
                  <div className="secondary-links-group9">
                    <div className="overlat-text-link26">
                      <div className="text-link72">
                        Digital Transformation in Construction: Leveraging
                        Technology for Efficiency and Quality.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="our-culture-links-container4">
                  <div className="overlat-text-link40">
                    <b className="text-link71">Expertise</b>
                  </div>
                  <div className="secondary-links-group9">
                    <div className="overlat-text-link26">
                      <div className="text-link72">
                        Digital Transformation in Construction: Leveraging
                        Technology for Efficiency and Quality.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="primary-nav-link-container"
            onClick={onPrimaryNavLinkContainer1Click}
          >
            <div className="overlat-text-link-wrapper">
              <div className="overlat-text-link24">
                <b className="text-link70">Careers</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-button-container">
        <div className="primary-button-wrapper">
          <div className="primary-button4">
            <b className="button19">Contact Us</b>
          </div>
        </div>
        <div className="social-links">
          <div className="overlat-text-link60">
            <div className="text-link72">Twitter</div>
          </div>
          <div className="overlat-text-link60">
            <div className="text-link72">Instagram</div>
          </div>
          <div className="overlat-text-link60">
            <div className="text-link72">Facebook</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabletmobileOverlayMenu;
