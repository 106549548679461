import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OurServices.css";

const OurServices = () => {
  const navigate = useNavigate();

  const onTextGroupWithCTAThinklabGClick = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  return (
    <div className="our-services-overlay">
      <div
        className="text-group-with-cta-thinklab-g1"
        onClick={onTextGroupWithCTAThinklabGClick}
      >
        <div className="text-group-thinklab8">
          <div className="heading-block-text51">
            <b className="heading-block-text52">Our Services</b>
          </div>
          <div className="paragraph-block-text23">
            <div className="lorem-ipsum-dolor25">
              Unlock success with our tailored solutions. From concept to
              completion, we're here to elevate your projects and drive
              exceptional results. Explore our services today.
            </div>
          </div>
        </div>
        <div className="secondary-button1">
          <b className="button18">Explore Our Story</b>
        </div>
      </div>
      <div className="our-company-links-container-group">
        <div className="our-company-links-container1">
          <div className="heading-block-text51">
            <b className="heading-block-text54">Strategy</b>
          </div>
          <div className="overlat-text-link12">
            <b className="text-link58">Strategy</b>
          </div>
          <div className="secondary-links-group2">
            <div className="heading-block-text51">
              <div className="text-link59">{`ESG Strategy`}</div>
            </div>
            <div className="overlat-text-link14">
              <div className="text-link59">Community</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">DE&I</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Environmental</div>
            </div>
            <div className="overlat-text-link14">
              <div className="text-link59">Innovation</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Safety & Wellness</div>
            </div>
           
          </div>
        </div>
        <div className="our-company-links-container1">
          <div className="heading-block-text51">
            <b className="heading-block-text54">Expertise</b>
          </div>
          <div className="overlat-text-link14">
            <b className="heading-block-text54">Expertise</b>
          </div>
          <div className="secondary-links-group2">
            <div className="heading-block-text51">
              <div className="text-link59">Engineering Service</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Road Construction</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Project Management</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Risk Management</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">{`Insurance & Safety`}</div>
            </div>
            <div className="heading-block-text51">
              <div className="text-link59">Energy Service</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
