import { useState, useCallback } from "react";

import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import "./Contact.css";
import NavBar from "../components/NavBar";

const Contact = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  return (
    <>
      <div className="contact">
        <NavBar/>
        <div className="taglinebody1">
          <div className="taglinetextgroupthinklab1">
            <div className="frame">
              <b className="taglineheadingblocktext1">Contact Us</b>
            </div>
            <div className="taglineparagraph-block1">
              <div className="taglineparagraph-block-text-container">
                <p className="connect-collaborate-construc">{`Connect, Collaborate, Construct: `}</p>
                <p className="connect-collaborate-construc">
                  Reach out to Thinklab Group for Seamless Communication and
                  Unparalleled Solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-section-thinklab2">
          <div className="text-group-thinklab5">
            <div className="heading-block-text29">
              <b className="heading-block-text30">Contact Us</b>
            </div>
            <div className="paragraph-block-text10">
              <div className="lorem-ipsum-dolor5">
                We appreciate your interest in Thinklab Group. Whether you're
                seeking assistance or have a general inquiry, we're eager to
                engage. Send us a message, and you can expect a prompt response.
              </div>
            </div>
          </div>
          <div className="contact-us-lower-container2">
            <div className="contact-form-thinklab2">
              <div className="upper-contact-form-section2">
                <div className="heading-block-text31">
                  <b className="heading-block-text32">Contact Form</b>
                </div>
                <div className="input-groups-container-thinkla2">
                  <div className="input-group-single-thinklab4">
                    <div className="frame">
                      <div className="form-label-text13">Name</div>
                    </div>
                    <input
                      className="input-field-single4"
                      placeholder="Enter Your Full Name"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab4">
                    <div className="frame">
                      <div className="form-label-text13">Email</div>
                    </div>
                    <input
                      className="input-field-single4"
                      placeholder="Enter Your Email Address"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab4">
                    <div className="frame">
                      <div className="form-label-text13">Message</div>
                    </div>
                    <textarea
                      className="input-field-multi-line2"
                      placeholder="Enter Your Message"
                    />
                  </div>
                </div>
              </div>
              <button className="primary-button2">
                <b className="button11">Send Message</b>
              </button>
            </div>
            <div className="contact-address-card2">
              <div className="heading-block-text31">
                <b className="heading-block-text32">Contact Address</b>
              </div>
              <div className="contat-info-buttom-section2">
                <div className="address-info-container2">
                  <img
                    className="address-icon2"
                    alt=""
                    src="/address-icon.svg"
                  />
                  <div className="secondary-text-link18">
                    <div className="lorem-ipsum-dolor5">
                      87 Samuel Ladoke Akintola Boulevard, Garki 2, Abuja
                    </div>
                  </div>
                </div>
                <div className="call-info-container2">
                  <img className="address-icon2" alt="" src="/call-icon.svg" />
                  <div className="secondary-text-link18">
                    <div className="lorem-ipsum-dolor5">+234 9084359943</div>
                  </div>
                </div>
                <div className="address-info-container2">
                  <img className="mail-icon2" alt="" src="/mail-icon.svg" />
                  <div className="secondary-text-link18">
                    <div className="lorem-ipsum-dolor5">
                      Project@thinklab.com.ng
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-thinklab2">
          <div className="footer-upper-container2">
            <div className="logotaglinesocial-links-cont2">
              <div className="footer-logo-and-tagline-contai2">
                <img
                  className="thinklab-logo-icon5"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text11">
                  <div className="lorem-ipsum-dolor5">
                    <p className="connect-collaborate-construc">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="connect-collaborate-construc">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-white3">
                <img
                  className="twitter-logo-icon3"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon3"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon3"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link2">
              <div className="frame">
                <div className="form-label-text13">Our Company</div>
              </div>
              <div className="frame">
                <div className="form-label-text13">Our Services</div>
              </div>
              <div className="frame">
                <div className="form-label-text13">Our Projects</div>
              </div>
              <div className="frame">
                <div className="form-label-text13">{`News & Insights`}</div>
              </div>
              <div className="frame">
                <div className="form-label-text13">Careers</div>
              </div>
              <div className="frame">
                <div className="form-label-text13">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved2">
            <div className="copyright-container">
              <img className="twitter-logo-icon3" alt="" src="/copyright.svg" />
              <div className="frame">
                <div className="div2">2024</div>
              </div>
            </div>
            <div className="div2">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
      {/* {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Contact;
