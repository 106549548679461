import { useState, useCallback } from "react";

import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import "./Projects.css";
import NavBar from "../components/NavBar";

const Projects = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  const onSingleProjectCardClick = useCallback(() => {
    // Please sync "Project" to the project
  }, []);

  return (
    <>
      <div className="projects">
        <NavBar/>
        <div className="taglinebody3">
          <div className="taglinetextgroupthinklab3">
            <div className="taglineheadingblock4">
              <b className="taglineheadingblocktext3">Our Projects</b>
            </div>
            <div className="taglineparagraph-block3">
              <div className="taglineparagraph-block-text1">
                Explore Thinklab Group's Showcase of Exceptional Projects, Where
                Innovation Meets Construction Excellence.
              </div>
            </div>
          </div>
        </div>
        <div className="our-project-section">
          <div className="subtext-group-thinklab1">
            <div className="subheading-block">
              <b className="subheading-block-text2">Our Project</b>
            </div>
            <div className="single-project-paragraph-block">
              <div className="single-project">
                Dive into Our Portfolio, A Testament to Precision, Innovation,
                and Unparalleled Mastery. Each Project Tells a Story of
                Excellence, Unfolded by Thinklab Group.
              </div>
            </div>
          </div>
          <div className="many-project-cards">
            <div
              className="single-project-card"
              onClick={onSingleProjectCardClick}
            >
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Conversion of Shiroro Hotel to IBBUTH
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Niger, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-project-img-container1@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Transformation of the existing Shiroro Hotel into a modern
                      teaching hospital for IBB University, providing
                      state-of-the-art healthcare facilities and infrastructure
                      for medical education and research.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card1">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Construction of Mass Housing Infrastructure
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Yobe State, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container2@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Development of affordable housing units for low-income
                      families, providing a comfortable and secure living
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading2">
                    Damaturu City Centre Shopping Mall
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Yobe, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container3@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Construction of a modern shopping mall, offering retail
                      spaces, restaurants, and entertainment options, enhancing
                      the city's commercial and social hub.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Zamfara Mass Housing Project
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Zamfara, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container4@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Development of affordable housing units for low-income
                      families, providing a comfortable and secure living
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Damaturu Green Economic City
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Yobe, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container5@2x.png"
                />
                <div className="single-project-text-paragraph5">
                  <div className="single-project-text-container4">
                    <p className="transformation-of-the">
                      Creation of a sustainable and eco-friendly city,
                      incorporating green architecture, renewable energy, and
                      innovative urban planning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Niger Three Arms Zone
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Niger, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container6@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Construction of new blocks for the Executive, Legislative,
                      and Judiciary arms of government, providing modern
                      facilities for effective governance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">
                    Niger State Trailer Park
                  </b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Niger, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container7@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      {" "}
                      Development of a modern trailer park, providing a safe and
                      efficient facility for heavy-duty vehicles, promoting
                      economic growth and reducing environmental impact.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-project-card2">
              <div className="single-project-text-group-thin">
                <div className="single-project-heading-block">
                  <b className="single-project-heading">Bauchi Mass Housing</b>
                </div>
                <div className="single-project-paragraph-block">
                  <div className="single-project">Bauchi, Nigeria</div>
                </div>
              </div>
              <div className="single-project-bottom-containe">
                <img
                  className="single-project-project-img-con"
                  alt=""
                  src="/single-project-our-project-img-container8@2x.png"
                />
                <div className="single-project-text-paragraph">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Development of affordable housing units for low-income
                      families, providing a comfortable and secure living
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-navigation-numbers1">
            <div className="page-arrow1">
              <img
                className="stat-minus-1-icon21"
                alt=""
                src="/stat-minus-11.svg"
              />
            </div>
            <div className="page-number4">
              <div className="div9">1</div>
            </div>
            <div className="page-number5">
              <div className="div9">2</div>
            </div>
            <div className="wrapper2">
              <div className="div11">...</div>
            </div>
            <div className="page-number5">
              <div className="div9">3</div>
            </div>
            <div className="page-number5">
              <div className="div9">4</div>
            </div>
            <img className="page-arrow-icon1" alt="" src="/page-arrow@2x.png" />
          </div>
        </div>
        <div className="footer-section-thinklab4">
          <div className="footer-upper-container4">
            <div className="logotaglinesocial-links-cont4">
              <div className="footer-logo-and-tagline-contai4">
                <img
                  className="thinklab-logo-icon9"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text17">
                  <div className="single-project">
                    <p className="transformation-of-the">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="transformation-of-the">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-white5">
                <img
                  className="twitter-logo-icon5"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon5"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon5"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link4">
              <div className="secondary-text-link33">
                <div className="div11">Our Company</div>
              </div>
              <div className="secondary-text-link33">
                <div className="div11">Our Services</div>
              </div>
              <div className="secondary-text-link33">
                <div className="div11">Our Projects</div>
              </div>
              <div className="secondary-text-link33">
                <div className="div11">{`News & Insights`}</div>
              </div>
              <div className="secondary-text-link33">
                <div className="div11">Careers</div>
              </div>
              <div className="secondary-text-link33">
                <div className="div11">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved4">
            <div className="copyright-parent2">
              <img className="twitter-logo-icon5" alt="" src="/copyright.svg" />
              <div className="secondary-text-link33">
                <div className="div14">2024</div>
              </div>
            </div>
            <div className="div14">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
     {/*  {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Projects;
