import { useState, useCallback } from "react";

import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import NavBar from "../components/NavBar";

const Services = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  return (
    <>
      <div className="services">
        <NavBar/>
        <div className="taglinebody">
          <div className="taglinetextgroupthinklab">
            <div className="taglineheadingblock">
              <div className="container">
                <b className="taglineheadingblocktext">Our Service</b>
              </div>
              <div className="taglineparagraph-block">
                <div className="taglineparagraph-block-texr">
                  Empowering Progress, One Solution at a Time: Discover the
                  Exceptional Services of Thinklab Group.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-service-section">
          <div className="subtext-group-thinklab">
            <div className="subheading-block-text">
              <b className="subheading-block-text1">Our Service</b>
            </div>
            <div className="subparagraph-block">
              <div className="subparagraph-block-text">
                Unleashing Comprehensive Solutions: Explore a spectrum of
                services tailored to meet your unique needs. At Thinklab Group,
                we take pride in delivering excellence across diverse
                industries. Your vision, our commitment — discover the
                possibilities.
              </div>
            </div>
          </div>
          <div className="our-services-cards-container">
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Innovation</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container1@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-servicetext-group-thinklab">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        <p className="at-thinklab-we">
                          At Thinklab, we drive innovation by providing
                          cutting-edge research and development services,
                          generating and incubating innovative ideas, and
                          designing and developing innovative products and
                          services that meet the evolving needs of our clients.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Finance</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container2@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-servicetext-group-thinklab">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        <p className="at-thinklab-we">
                          Our team provides financial advisory services,
                          advising on investment, funding, and growth
                          strategies. We also assist with capital raising,
                          helping businesses secure funding through various
                          channels, including grants, loans, and equity
                          investments. Additionally, we offer financial
                          management services, including accounting, budgeting,
                          and forecasting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Design and Architecture</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container3@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        Thinklab Group offers comprehensive Architecture and
                        Design services tailored to meet the unique needs of our
                        clients across various sectors. Our team of experienced
                        architects, designers, and engineers collaborate closely
                        with clients to deliver innovative and functional design
                        solutions.
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Engineering Service</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container4@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        We provide a wide range of Engineering Services,
                        leveraging our expertise and experience to deliver
                        innovative and reliable solutions for various projects.
                        Our team of skilled engineers, technicians, and project
                        managers collaborate closely with clients to address
                        complex challenges and achieve optimal outcomes.
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Project Management</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container5@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-servicetext-group-thinklab">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        Project Management is a critical aspect of successful
                        construction projects, and Thinklab Group excels in
                        providing comprehensive Project Management services. Our
                        experienced project managers oversee all phases of a
                        project, from initiation to completion, ensuring
                        efficient execution, adherence to timelines, and
                        delivery of high-quality results..
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Value Engineering</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container6@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        <p className="at-thinklab-we">
                          Thinklab provides value engineering to efficiently
                          solve project challenges. Experienced staff apply
                          extensive know how to contextualize and analyze
                        </p>
                        <p className="at-thinklab-we">
                          engineering designs, and propose changes that identify
                          and eliminate unwanted costs while maintaining or
                          enhancing quality, value and functional performance,
                          considering maintenance, management, life cycle design
                          and performance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading4">Mega City Development</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container7@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        Mega City Development involves complex urban planning,
                        infrastructure development, and sustainable growth
                        strategies on a large scale. Thinklab Group specializes
                        in Mega City Development services, leveraging our
                        expertise in city planning, construction, and project
                        management to create thriving and sustainable urban
                        environments.
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Mass Housing Development</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container8@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">
                        Transform communities with Thinklab Group's Mass Housing
                        solutions. Powered by expertise and innovation, we
                        specialize in creating sustainable and affordable mass
                        housing projects. Our streamlined approach combines
                        innovative construction methods with thoughtful urban
                        planning, ensuring quality living for all. Choose
                        Thinklab Group for efficient, cost-effective, and
                        excellently designed mass housing solutions.
                      </div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-service-card">
              <div className="our-serviceheading-block">
                <b className="our-service-heading">Market Research</b>
              </div>
              <div className="our-service-lower-container">
                <img
                  className="our-service-img-container1"
                  alt=""
                  src="/our-service-img-container9@2x.png"
                />
                <div className="our-service-thinklab-group">
                  <div className="our-serviceheading-block-text2">
                    <div className="our-serviceheading-block-text">
                      <b className="our-serviceheading-blocktext">
                        Heading Block Text
                      </b>
                    </div>
                    <div className="our-service-paragraph-block">
                      <div className="subparagraph-block-text">{`we also offer market research and analysis to help businesses understand their target audience and market trends, as well as business development services to help businesses develop and implement growth strategies, including market expansion and partnerships. `}</div>
                    </div>
                  </div>
                  <div className="our-service-button-text">
                    <b className="button1">Read More</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-service-section">
          <div className="text-group-thinklab4">
            <div className="subheading-block-text">
              <b className="subheading-block-text1">Contact Us</b>
            </div>
            <div className="subparagraph-block">
              <div className="subparagraph-block-text">
                We appreciate your interest in Thinklab Group. Whether you're
                seeking assistance or have a general inquiry, we're eager to
                engage. Send us a message, and you can expect a prompt response.
              </div>
            </div>
          </div>
          <div className="contact-us-lower-container1">
            <div className="contact-form-thinklab1">
              <div className="upper-contact-form-section1">
                <div className="heading-block-text25">
                  <b className="our-service-heading4">Contact Form</b>
                </div>
                <div className="input-groups-container-thinkla1">
                  <div className="input-group-single-thinklab2">
                    <div className="container">
                      <div className="form-label-text7">Name</div>
                    </div>
                    <input
                      className="input-field-single2"
                      placeholder="Enter Your Full Name"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab2">
                    <div className="container">
                      <div className="form-label-text7">Email</div>
                    </div>
                    <input
                      className="input-field-single2"
                      placeholder="Enter Your Email Address"
                      type="text"
                    />
                  </div>
                  <div className="input-group-single-thinklab2">
                    <div className="container">
                      <div className="form-label-text7">Message</div>
                    </div>
                    <textarea
                      className="input-field-multi-line1"
                      placeholder="Enter Your Message"
                    />
                  </div>
                </div>
              </div>
              <button className="primary-button1">
                <b className="button10">Send Message</b>
              </button>
            </div>
            <div className="contact-address-card1">
              <div className="heading-block-text25">
                <b className="our-service-heading4">Contact Address</b>
              </div>
              <div className="contat-info-buttom-section1">
                <div className="address-info-container1">
                  <img
                    className="address-icon1"
                    alt=""
                    src="/address-icon.svg"
                  />
                  <div className="secondary-text-link9">
                    <div className="subparagraph-block-text">
                      87 Samuel Ladoke Akintola Boulevard, Garki 2, Abuja
                    </div>
                  </div>
                </div>
                <div className="call-info-container1">
                  <img className="address-icon1" alt="" src="/call-icon.svg" />
                  <div className="secondary-text-link9">
                    <div className="subparagraph-block-text">
                      +234 9084359943
                    </div>
                  </div>
                </div>
                <div className="address-info-container1">
                  <img className="mail-icon1" alt="" src="/mail-icon.svg" />
                  <div className="secondary-text-link9">
                    <div className="subparagraph-block-text">
                      Project@thinklab.com.ng
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-thinklab1">
          <div className="footer-upper-container1">
            <div className="logotaglinesocial-links-cont1">
              <div className="footer-logo-and-tagline-contai1">
                <img
                  className="thinklab-logo-icon3"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text9">
                  <div className="subparagraph-block-text">
                    <p className="at-thinklab-we">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="at-thinklab-we">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-white2">
                <img
                  className="twitter-logo-icon2"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon2"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon2"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link1">
              <div className="container">
                <div className="form-label-text7">Our Company</div>
              </div>
              <div className="container">
                <div className="form-label-text7">Our Services</div>
              </div>
              <div className="container">
                <div className="form-label-text7">Our Projects</div>
              </div>
              <div className="container">
                <div className="form-label-text7">{`News & Insights`}</div>
              </div>
              <div className="container">
                <div className="form-label-text7">Careers</div>
              </div>
              <div className="container">
                <div className="form-label-text7">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved1">
            <div className="copyright-group">
              <img className="twitter-logo-icon2" alt="" src="/copyright.svg" />
              <div className="container">
                <div className="div1">2024</div>
              </div>
            </div>
            <div className="div1">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
 {/*      {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Services;
