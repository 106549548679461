const newsData = [

    {
        id: 'birningwari',
        date: '26 August, 2024',
        title: "Thinklab Group guarantees quality delivery on construction of 35.6km bagoma-gagumi road project",
        type: "News",
        location: "Birnin Gwari, Kaduna",
        firstImage: "birningwari0.jpg",
        //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
        fullDescription: `
        Thinklab Group Limited, a leading conglomerate with a strong presence in innovation, hi-tech projects, infrastructure development, and finance, has donated N3 million to the Yobe State Government to support the relief efforts for victims of the recent flooding in the state.
The donation was presented by Dr. Abba Damuna, Project Director of Thinklab Group Limited, alongside Engr. Usman Hamidu, Head of Quality Control, and other team members, to Dr. Mohammed Jalo, Director of Operations, Yobe State Emergency Agency (SEMA).
Dr. Jalo expressed his gratitude to Thinklab Group Limited for the generous donation, commending the company's cordial partnership with the state government. He emphasized that Thinklab Group is truly a stakeholder in Yobe State, and their contribution will go a long way in alleviating the suffering of the affected communities.
The donation is a testament to Thinklab Group Limited's commitment to supporting those in need, as expressed in a letter dated August 26, 2024, to the Executive Secretary, Yobe State Emergency Agency (SEMA). The letter highlighted the company's deepest sympathy and concern for the victims of the recent flooding and commended the excellent response and efforts of the Yobe State Government under the leadership of His Excellency, the Executive Governor of Yobe State, Hon. Mai Mala Buni.
Dr. Abba Damuna, Project Director of Thinklab Group Limited, stated that the company believes its modest contribution will complement the state government's efforts in alleviating the suffering of the affected communities. He expressed appreciation for the tireless efforts of Dr. Jalo and his team, as well as all other stakeholders involved in the relief efforts.
This donation demonstrates Thinklab Group Limited's dedication to corporate social responsibility and its commitment to supporting the people of Yobe State during this challenging time.
        `,
        gallery: [
            "birningwari2.jpg",
            "birningwari3.jpg",
            "birningwari4.jpg",
            "birningwari.jpg"
        ]
    },

    // {
    //     id: 'damaturuflood',
    //     date: '26 August, 2024',
    //     title: "Thinklab Group limited donates 3 million Naira to Yobe State government to support flood victims",
    //     type: "News",
    //     location: "Damaturu, Yobe",
    //     firstImage: "flood.jpg",
    //     //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
    //     fullDescription: `
    //     ThinkLab Group Donates ₦3 Million to Support Yobe State Flood Victims
    //     The Group Chief Executive Officer of ThinkLab Group Dr. Sa'id Alkali Kori through the GMD Dr. Babagana Mohammed donated the sum of Three Million Naira (N 3,000,000.00) to Yobe SEMA as part of ThinkLab Group’s ongoing commitment to complement the Yobe State Government's efforts in providing relief to disaster-stricken communities. During the presentation, Dr. Abba Damuna, Director of Projects at ThinkLab Group, expressed the organization’s deep concern for the flood victims and praised the swift response by Yobe State Government.
    //     In a letter addressed to Dr. Mohammed Goje, Executive Secretary of SEMA, Dr. Babagana Mohammed, Group Managing Director of ThinkLab Group, conveyed the organization’s sympathy for the victims and admiration for the leadership of Yobe State Governor, Hon. Mai Mala Buni CON. The letter highlighted the Governor’s dedication to providing timely relief and his visionary approach to rebuilding and restoring the affected communities with a focus on resilience and sustainability.
    //     “We believe our modest contribution will complement the state government's efforts in alleviating the suffering of the affected communities,” Dr. Mohammed stated. He also expressed confidence in SEMA's ability to judiciously utilize the donation to ensure it reaches those in dire need.
    //     In response, Dr. Goje  M, on behalf of the Yobe State Government, expressed profound gratitude to ThinkLab Group for their generosity. He emphasized that the donation would play a crucial role in supporting the ongoing relief efforts, underscoring that the funds would be distributed promptly and effectively to the most vulnerable households. Emphasizing that 100 flood victims in the Dikumari 48, Ganyen Kura 32 and Nayi Nawa Bukka Bakwai 20 communities of Damaturu Local Government Area will be given cash stimulus of N30,000.00 each.
    //     Dr. Goje also acknowledged ThinkLab Group’s impactful role in various sectors and their commitment to corporate social responsibility through the ThinkLab Charity Foundation. He assured that the collaboration between SEMA and ThinkLab Group would continue to yield positive results, contributing to the overall welfare of the people of Yobe State.
    //     The donation from ThinkLab Group not only provides immediate relief but also reinforces the importance of public-private partnerships in addressing the challenges faced by communities during disasters. As Yobe State continues to recover from the flood crisis, the support from organizations like ThinkLab Group underscores a collective commitment to rebuilding a stronger, more resilient future for the region..
    //     `,
    //     gallery: [
    //         "flood1.jpg",
    //         "flood2.jpg",
    //         "flood.jpg"
    //     ]
    // },

    {
        id: 'aihs',
        date: '25 July, 2024',
        title: "Dr Sa'id Alkali Kori is the Construction Personality of The Year by the African International Housing Show in colloboration with the Federal Ministry of Housing. ",
        type: "News",
        location: "Abuja",
        firstImage: "aihs.jpg",
        //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
        fullDescription: `Thinklab Group, comprising various subsidiaries such as Thinklab Media, Thinklab International Consulting, Thinklab Digital Technology, Thinklab Energy, Thinklab Properties, and Thinklab Agro Allied, will showcase its innovative solutions and projects at the show, underscoring Thinklab’s commitment to addressing Africa’s housing challenges and aligns with the event’s objectives, making it a strategic move to drive progress in the sector.

        The show will feature over 400 exhibitors, including real estate professionals, developers, and investors, providing a unique platform for participants to connect with leading brands and key industry players. Attendees can expect informative sessions, networking opportunities, and access to the latest advancements in affordable housing.
        
        The AIHS is notable for its international appeal, with participation from over twenty countries. This year’s event, hosted by the Honourable Minister of Housing and Urban Development, Architect Ahmed Musa Dangiwa, will include special sessions, global wisdom summits, and collaborative networking opportunities.
        `,
        gallery: [
            "aihs1.jpg",
            "aihs.jpg",
            "aihs3.jpg",
            "aihs4.jpg",
            "aihs6.jpg"
        ]
    },

    
    {
        id: 'milleniumcity',
        date: '24 July, 2024',
        title: "Senator Uba Sani grounds breaks 14.8km Kaduna Millennium city ring road project, Thinklab Group takes charge.",
        type: "News",
        location: "Millenium City, Kaduna",
        firstImage: "millenium9.jpg",
        //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
        fullDescription: `
        
Kaduna State Governor, Senator Uba Sani, has officially launched the construction of a 14.8km dual road project in Millennium City, Chikun LGA, marking a significant milestone in the state's infrastructure development journey. The groundbreaking ceremony, which took place at Danbushiya Junction, signals the commencement of the project, which will connect Danbushiya to other axis in Millennium City.
In his keynote address, Senator Uba Sani emphasized his administration's commitment to infrastructure development, highlighting its role in driving economic growth and improving the quality of life of citizens. He assured the public that the project will be delivered on time and within budget, demonstrating the government's dedication to efficient project management.
Thinklab Group, a renowned conglomerate with expertise in housing development, finance solutions, and infrastructure, has been appointed as the contractor for the project. With a proven track record in building construction, industrial facilities, civil structures, roads, and infrastructure, Thinklab Group is well-equipped to handle the project's demands, ensuring high-quality delivery.
The event was well-attended by dignitaries, including SSG, Cabinet Members, Traditional rulers, Community Stakeholders among others.
        `,
        gallery: [
            "millenium10.jpg",
            "millenium11.jpg",
            "millenium1.jpg",
            "millenium3.jpg",
            "millenium4.jpg",
            "millenium7.jpg",
            "millenium6.jpg",
        ]
    },



    
    
    {
    id: 'ecowasbank',
    date: '04 July, 2024',
    title: "Thinklab Group and Niger State Governement unveils massive development projects for greater suleja in collaboration with Ecowas Bank.",
    type: "News",
    location: "Zuma Rock, Abuja",
    firstImage: "ecowas.jpg",
    //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
    fullDescription: "Thinklab Group Limited hosted a joint delegation from the ECOWAS Bank for Development and Investment and the Niger State Ministry of Lands and Survey, led by Permanent Secretary Abdullahi Hussain, for a site visit and presentation at the Zuma Rock Resort conference room. The visit showcased various projects, including the proposed Madala Green Economy City, Trailer Park in Tafa/Dikko, and the conversion of the old Secretariat to a School of Health and Medical Sciences. Permanent Secretary Abdullahi Hussain emphasized the significance of the project to Niger State Governor Mohammed Umar Bago, stating that the State Government has prepared a development plan to guide growth in the proposed areas over the next ten years.   He highlighted the uniqueness of Greater Suleja, emphasizing its importance for the safety and security of the Federal Capital Territory (FCT).",
    gallery: [
        "ecowas2.jpg",
        "ecowas3.jpg",
        "ecowas4.jpg",
        "ecowas5.jpg"
    ]
    },

    {
    id: 'economiccity',
    date: '03 July, 2024',
    title: "Thinklab Group, hosted a delegation of representatives from the Niger State, on a site visit to the proposed Madala Green Economy City, and Trailer park in Tafa/Dikko.",
    type: "News",
    location: "Tafa/Dikko, Niger",
    firstImage: "economicCiy.jpg",
    //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
    fullDescription: "Thinklab Group hosted a delegation of representatives from the Niger State Ministry of Lands and Survey, led by the Permanent Secretary, on a site visit to the proposed Madala Green Economy City, and Trailer park in Tafa/Dikko.",
    gallery: [
    "economicCity1.jpg",
    "economicCity2.jpg",
    "economicCity3.jpg",
    "economicCiy.jpg"
    ]
    },

    {
    id: 'townplanners',
    date: '05 June, 2024',
    title: "Thinklab Group and Nigeria Institute of Town Planners signs historic mou on joint venture ",
    type: "News",
    location: "Garki, Abuja",
    firstImage: "townPlanners.jpg",
    //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
    fullDescription: "Thinklab Group Limited and the Nigeria Institute of Town Planners (NITP) have officially signed a Memorandum of Understanding (MOU) on a joint venture to develop a landmark project. In his welcome remark, The Chairman Thinklab Group Limited Dr Sa'id Kori disclosed that the project involve the financing and construction of four storey office structure, two residential building and infrastructural facilities, stating that, Thinklab Group responsibility is to partake on the financing of the four storey structure and two residential buildings with all the facilities while Town Planners are expected to provide the land of 7600sm as equity with the joint ventures. Dr Sa’id Kori asserts that, Thinklab Group will give a legacy project that will stand the test of time, while assuring town planners of commencing the project in two weeks time, adding that the organisation is fully capable. The president of the Nigeria Institute of Town Planners, Nathaniel Atebije expressed his delight, saying that, Thinklab Group is an incredible developer, and this is the beginning of a greater strides in the NTIP and greater partnership with Thinklab Group having seen the tremendous jobs they have executed across the country. He added that in no time the job will be delivered and commissioned.  While speaking, the director of Development Control, Mukhtar Galadima, said the signing of the MOU between the Nigeria Institute of Town Planners and Thinklab Group is an history making event, pledging to to grant all necessary permissions needed to undertake the project. Also speaking at the event, the founder of African International Housing show, Festus Adebayo expressed his satisfaction towards the success of the official signing of the MOU, charging both parties to keep to their promises and maintain professionalism.",
    gallery: [
    "townPlanners8.jpg",
    "townPlanners2.jpg",
    "townPlanners3.jpg",
    "townPlanners4.jpg",
    "townPlanners5.jpg"
    ]
    },

    {
    id: 'rafinguza',
    date: '04 June, 2024',
    title: "Governor Uba Sani's Vision for People-Centric Infrastructure Takes Shape, The Giant Thinklab Group awarded the major road projects.",
    type: "News",
    location: "Kaduna",
    firstImage: "rafinguza.jpg",
    //description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum magna sed, convallis ex.",
    fullDescription: "Kaduna State Governor, Uba Sani, has broken ground on two major road projects, marking a significant milestone in the state’s infrastructure development. Thinklab Group Limited, a leading construction company, has been awarded the contracts for both projects, which were launched simultaneously on June 1st, 2024. Mr Sani’s vision for people-centric infrastructure is evident in these projects, which aim to improve connectivity, enhance economic growth, and boost development in the region.",
    gallery: [
    "rafinguza1.jpg",
    "rafinguza2.jpg",
    "rafinguza3.jpg",
    "rafinguza.jpg"
    ]
    }                                                                  
    
]    



export default newsData;

    