import { useLocation } from "react-router-dom";
import "./SingleNews.css";
import { useEffect, useState } from "react";
import newsData from "../data/news";
import { FacebookShareButton } from "react-share";
import NavBar from "../components/NavBar";

const SingleNews = () => {



  const location = useLocation();
  //const id = location.pathname.split("/")[2]

const id = location.pathname.match(/\/news\/(.+)/)[1]


const currentPageUrl = window.location.href




  console.log('hgfghk',id)

  const [news, setNews] = useState({});

  // useEffect(()=>{

  //   const newsItem = newsData.filter((item) => (item.id) === id)[0]

  //   setNews(newsItem)
  
  //   console.log(newsItem)

  // }, [])


  useEffect(() => {
    const newsItem = newsData.filter((item) => (item.id) === id)[0];
    if (newsItem) {
      setNews(newsItem);
    }
  }, [id]);
  
 
    
  return (
    <div className="single-news">
      <NavBar/>
      <div className="taglinebody">
        <div className="taglinetextgroupthinklab">
          <div className="taglineheadingblock-single">
            <b className="taglineheadingblocktext">{`News & Insight`}</b>
          </div>
          <div className="taglineparagraph-block">
            <b className="taglineparagraph-block-text">
              Explore the Latest News and Insights in Innovation and Thought
              Leadership with Thinklab Group.
            </b>
          </div>
        </div>
      </div>
      <div className="individualnewspage">
        <div className="individualnewstitle">
          <div className="individualnewsgallerytitlebody">
            <div className="individualnewstitleheader">
              <b className="individualnewstitleheadertext">{news.type}</b>
            </div>
            <div className="individualnewstitleheaderparag">
              <b className="individualnewstitleheaderparag1">
              {news.title}
              </b>
            </div>
          </div>
          <div className="individualnewsdatelocation">
            <div className="individualnewsdatelocationdat">
              <div className="taglineheadingblock">
                <b className="individualnewsdatesubtext">Date</b>
              </div>
              <div className="individualnewsdatesubbody">
                <div className="individualnewsdatesubbodytext">
                 {news.date}
                </div>
              </div>
            </div>
            <div className="individualnewsdatelocationdat1">
              <div className="taglineheadingblock">
                <b className="individualnewsdatesubtext">Location</b>
              </div>
              <div className="individualnewslocationsubbody">
                <div className="individualnewsdatesubbodytext">{news.location}</div>
              </div>
            </div>
          </div>
        </div>
{/*         <div className="individualnewsheaderbody">
          <div className="heading-block-text">
            <b className="heading-block-text1">Introduction:</b>
          </div>
          <div className="individualnewsheaderbodytext">
            <div className="individualnewsheaderbodysubtex">
              In today's rapidly evolving construction industry, the concept of
              sustainability has taken center stage, with companies like
              Thinklab Group leading the way in adopting innovative practices
              and technologies. Sustainable construction not only benefits the
              environment but also contributes to long-term cost savings and
              enhances the overall quality of projects.
            </div>
          </div>
        </div> */}
        <img
          className="individualnewsheaderbodyimg-icon"
          alt=""
          src={`/${news.firstImage}`}
        />
        <div className="individualnewsheaderbody">
          {/* <div className="taglineheadingblock">
            <b className="newsbodytextgrouptiltetext">Introduction:</b>
          </div> */}
          <div className="individualnewsheaderbodytext">
            <div className="individualnewsheaderbodysubtex">
              {news.fullDescription}
            </div>
          </div>
        </div>
        {/* <div className="individualnewsheaderbody">
          <div className="taglineheadingblock">
            <b className="newsbodytextgrouptiltetext">Trends and innovation:</b>
          </div>
          <div className="individualnewsheaderbodytext">
            <div className="individualnewsheaderbodysubtex">
              One of the key trends in sustainable construction is the use of
              renewable energy sources such as solar panels, wind turbines, and
              geothermal systems. Thinklab Energy Limited, a subsidiary of
              Thinklab Group, specializes in integrating renewable energy
              solutions into construction projects, reducing carbon footprints
              and promoting energy independence.
            </div>
          </div>
        </div>
        <div className="individualnewsheaderbody">
          <div className="taglineheadingblock">
            <b className="newsbodytextgrouptiltetext">
              Case Studies and best practices:
            </b>
          </div>
          <div className="individualnewsheaderbodytext">
            <div className="individualnewsheaderbodysubtex">
              Thinklab Group's portfolio includes impressive case studies of
              sustainable construction projects. For example, the implementation
              of green building materials, energy-efficient designs, and waste
              management systems has resulted in LEED certification for several
              projects, showcasing the company's commitment to environmental
              stewardship.
            </div>
          </div>
        </div>
        <div className="individualnewsheaderbody">
          <div className="taglineheadingblock">
            <b className="newsbodytextgrouptiltetext">
              Technological Advancement:
            </b>
          </div>
          <div className="individualnewsheaderbodytext">
            <div className="individualnewsheaderbodysubtex">
              Advanced technologies like Building Information Modeling (BIM) and
              Internet of Things (IoT) are revolutionizing the construction
              sector. Thinklab Digital Technology Limited leverages these tools
              to optimize project workflows, improve communication among
              stakeholders, and monitor resource utilization in real time,
              leading to more sustainable outcomes.
            </div>
          </div>
        </div> */}


       
   
        <div className="newssharepost">
          <div className="taglineheadingblock">
            <b className="individualnewsdatesubtext">Share this Insight</b>
          </div>
          <div className="newssharepostgroup">
            <img
              className="newssharepostinstagram-icon"
              alt=""
              src="/instagram-logosvg2.svg"
            />
            <img
              className="newssharepostinstagram-icon"
              alt=""
              src="/twitter-logo2.svg"
            />
            <FacebookShareButton
            url = {currentPageUrl}
            
            >
            <img
              className="newssharepostinstagram-icon"
              alt=""
              src="/facebook-symbolsvg2.svg"
            />
            </FacebookShareButton>
          
            <div className="newssharepostcopy">
              <div className="newssharepostcopyicon">
                <img
                  className="content-copy-icon"
                  alt=""
                  src="/content-copy.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <img className="newsdevider-icon" alt="" src="/newsdevider.svg" />
      </div>
      
      <div className="individualnewsgallerysection">
        <div className="individualnewsgallerytitlebody">
          <div className="individualnewstitleheader">
            <b className="individualnewsgallerysectionhe1">Gallery</b>
          </div>
          <div className="relatedarticlescardscardparagr">
            <div className="individualnewsheaderbodysubtex">
              The Project Gallery offers a comprehensive view of our commitment
              to excellence, sustainability, and community impact through visual
              representations, interactive displays, and informative narratives.
              It serves as a testament to our dedication to quality, innovation,
              and transparency in every stage of project development.
            </div>
          </div>
        </div>
        <div className="individualnewsgallerysectionsl">
          <div className="individualnewsgallerysectionsl1">


           
              {/* <img
                className="individualnewssliderimg-icon"
                alt=""
                src="/individualnewssliderimg@2x.png"
              />

            
          
              <img
                className="individualnewssliderimg-icon"
                alt=""
                src="/individualnewssliderimg@2x.png"
              /> */}
              {news.gallery && news.gallery.map((image, index) => (
              <div key={index}  >
                <img src={`/${image}`} alt="" className="individualnewssliderimg-icon"/>
              </div>
            ))}


          




          </div>
        </div>

        
        <div className="individualnewsgallerysectionsl7">
              <img
                className="individualnewsgallerysectionsl-icon"
                alt=""
                src="/individualnewsgallerysectionsliderbodycontrolprev.svg"
              />
              <img
                className="individualnewsgallerysectionsl-icon1"
                alt=""
                src="/individualnewsgallerysectionsliderbodycontrolnext.svg"
              />
            </div>


      </div>


      {/* <div className="relatedarticles">
        <div className="relatedarticlesheading">
          <b className="relatedarticlesheadingtext">Related Article</b>
        </div>
        <div className="relatedarticlescards">
          <div className="relatedarticlescardscard">
            <div className="taglineheadingblock">
              <b className="relatedarticlescardscardheadin1">Insight</b>
            </div>
            <img
              className="relatedarticlescardscardimg-icon"
              alt=""
              src="/relatedarticlescardscardimg@2x.png"
            />
            <div className="relatedarticlescardscardparagr">
              <div className="individualnewsheaderbodysubtex">
                Thinklab Group and DumDum International celebrates 5 years of
                partnership.
              </div>
            </div>
            <div className="relatedarticlescardscardbutton">
              <b className="button">Read More</b>
            </div>
          </div>
          <div className="relatedarticlescardscard">
            <div className="taglineheadingblock">
              <b className="relatedarticlescardscardheadin1">Insight</b>
            </div>
            <img
              className="relatedarticlescardscardimg-icon"
              alt=""
              src="/relatedarticlescardscardimg@2x.png"
            />
            <div className="relatedarticlescardscardparagr">
              <div className="individualnewsheaderbodysubtex">
                Thinklab Group and DumDum International celebrates 5 years of
                partnership.
              </div>
            </div>
            <div className="relatedarticlescardscardbutton">
              <b className="button">Read More</b>
            </div>
          </div>
          <div className="relatedarticlescardscard">
            <div className="taglineheadingblock">
              <b className="relatedarticlescardscardheadin1">Insight</b>
            </div>
            <img
              className="relatedarticlescardscardimg-icon"
              alt=""
              src="/relatedarticlescardscardimg1@2x.png"
            />
            <div className="relatedarticlescardscardparagr">
              <div className="individualnewsheaderbodysubtex">
                Thinklab Group and DumDum International celebrates 5 years of
                partnership.
              </div>
            </div>
            <div className="relatedarticlescardscardbutton">
              <b className="button">Read More</b>
            </div>
          </div>
        </div>
      </div> */}
      <div className="subscribe-section">
        <div className="subscribe-text-group-thinklab">
          <div className="taglineheadingblock">
            <b className="heading-block-text15">Subscribe to our newsletter.</b>
          </div>
          <div className="paragraph-block-text6">
            <div className="individualnewsheaderbodysubtex">
              Stay up to date with Thinklab group news ands events
            </div>
          </div>
        </div>
        <div className="subscribe-input-block">
          <input
            className="input-field-single"
            placeholder="Enter Your Email"
            type="text"
          />
          <div className="subscribe-primary-button">
            <b className="button">Subscribe</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
