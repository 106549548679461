import { useState, useCallback } from "react";

import PortalPopup from "../components/PortalPopup";
import { Link, useNavigate } from "react-router-dom";
import "./News.css";
import NavBar from "../components/NavBar";
import newsData from "../data/news";

const News = () => {
  const [isTabletmobileOverlayMenuOpen, setTabletmobileOverlayMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const onNavBarLogoWrapperClick = useCallback(() => {
    // Please sync "Home" to the project
  }, []);

  const onNavitem3Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onNavitem4ContainerClick = useCallback(() => {
    navigate("/news");
  }, [navigate]);

  const onContactnavContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const openTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(true);
  }, []);

  const closeTabletmobileOverlayMenu = useCallback(() => {
    setTabletmobileOverlayMenuOpen(false);
  }, []);

  return (
    <>
      <div className="news">
       <NavBar/>
        <div className="taglinebody2">
          <div className="taglinetextgroupthinklab2">
            <div className="newsheading-block-text">
              <b className="taglineheadingblocktext2">{`News & Insight`}</b>
            </div>
            <div className="taglineparagraph-block2">
              <b className="taglineparagraph-block-text-news">
                Explore the Latest News and Insights in Innovation and Thought
                Leadership with Thinklab Group.
              </b>
            </div>
          </div>
        </div>
        <div className="newsinsight-section">
          <div className="newsinsight-filter-header">
            <div className="newssecondary-button">
              <b className="newsprimary-button-text">All</b>
            </div>
            <div className="newssecondary-button1">
              <b className="newsprimary-button-text">News</b>
            </div>
            <div className="newssecondary-button1">
              <b className="newsprimary-button-text">Insight</b>
            </div>
          </div>
          <div className="news-insight-cards-container">


          {newsData.map((newsItem, index) => (
                      <div className="news-container">
                      <div className="newsheading-block-text">
                        <b className="news-heading-block">News</b>
                      </div>
                      <img
                        className="news-insight-img-container1"
                        alt=""
                        src={newsItem.firstImage}
                      />
                      <div className="newsparagraph-block">
                        <div className="newsparagraph-block-text">
                        {newsItem.title}
                        </div>
                      </div>
                      <Link className='link' to={`/news/${newsItem.id}`}>
                      <div className="newsprimary-button">
                        <b className="newsprimary-button-text">Read More</b>
                      </div>
                      </Link>
                  </div>
          ))}


           {/*  <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/ntp.jpg"
              />
              <div className="newsparagraph-block">
                <div className="newsparagraph-block-text">
                Thinklab Group and Nigeria Institute of Town Planners Signs MOU on Joint Venture
                </div>
              </div>
              
              <Link className='link' to={ `/news/${1}`}>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
              </Link>
            </div> */}
{/* 
          <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/ubasani.jpg"
              />
              <div className="newsparagraph-block">
                <div className="newsparagraph-block-text">
                Governor Uba Sani's Vision for People-Centric Infrastructure Takes Shape, The Giant Thinklab Group awarded the major road projects.
                </div>
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
              </Link>
            </div> */}


          {/*   <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/news--insight-img-container1@2x.png"
              />
              <div className="newsparagraph-block">
                <div className="newsparagraph-block-text">
                  ThinkLab Charity Foundation announces full tuition scholarship
                  for 1000 JAMB candidates scoring above 200
                </div>
              </div>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
            </div>
            <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">Insight</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/news--insight-img-container-2@2x.png"
              />

              <div className="newsparagraph-block">
                <div className="newsparagraph-block-text">
                  Niger State government in partnership with Think-Lab Group
                  trains 200 youths on bread baking
                </div>
              </div>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
            </div>
            <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">Insight</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/news--insight-img-container-3@2x.png"
              />
              <div className="news-paragraph-block2">
                <div className="news-paragraph-block3">
                  Navigating City Development Challenges: Strategies for
                  Sustainable Urban Growth
                </div>
              </div>
              <Link className='link' to={ `/news/${1}`}>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
              </Link>
            </div>
            <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/news--insight-img-container1@2x.png"
              />
              <div className="news-paragraph-block-text">
                <div className="news-paragraph-block4">
                  ThinkLab sponsors JAMB for 1530 public school students in
                  Niger State
                </div>
              </div>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
            </div>
            <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container5"
                alt=""
                src="/news--insight-img-container5@2x.png"
              />
              <div className="news-paragraph-block-text">
                <div className="news-paragraph-block4">
                  Niger State Commissioner for Budget and Planning applauds
                  Thinklab Group contractors for their hard work
                </div>
              </div>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
            </div>
            <div className="news-container">
              <div className="newsheading-block-text">
                <b className="news-heading-block">News</b>
              </div>
              <img
                className="news-insight-img-container1"
                alt=""
                src="/news--insight-img-container6@2x.png"
              />
              <div className="news-paragraph-block2">
                <div className="news-paragraph-block4">
                  Thinklab Group partners as Platinum Sponsor of 2024 Africa
                  International Housing Show
                </div>
              </div>
              <div className="newsprimary-button">
                <b className="newsprimary-button-text">Read More</b>
              </div>
            </div> */}

            
          </div>

          
          <div className="page-navigation-numbers">
            <div className="page-arrow">
              <img
                className="stat-minus-1-icon16"
                alt=""
                src="/stat-minus-11.svg"
              />
            </div>
            <div className="page-number">
              <div className="div3">1</div>
            </div>
            <div className="page-number1">
              <div className="div3">2</div>
            </div>
            <div className="frame-div">
              <div className="div5">...</div>
            </div>
            <div className="page-number1">
              <div className="div3">3</div>
            </div>
            <div className="page-number1">
              <div className="div3">4</div>
            </div>
            <img className="page-arrow-icon" alt="" src="/page-arrow@2x.png" />
          </div>
        </div>
        <div className="subscribe-section">
          <div className="subscribe-text-group-thinklab">
            <div className="newsheading-block-text">
              <b className="heading-block-text36">
                Subscribe to our newsletter.
              </b>
            </div>
            <div className="paragraph-block-text12">
              <div className="newsparagraph-block-text">
                Lorem ipsum dolor sit amet consectetur. Lacinia est vitae at
                etiam ultricies quam purus a tortor dolor sit amet
              </div>
            </div>
          </div>
          <div className="subscribe-input-block">
            <input
              className="input-field-single6"
              placeholder="Enter Your Email"
              type="text"
            />
            <div className="subscribe-primary-button">
              <b className="newsprimary-button-text">Subscribe</b>
            </div>
          </div>
        </div>
        <div className="footer-section-thinklab3">
          <div className="footer-upper-container3">
            <div className="logotaglinesocial-links-cont3">
              <div className="footer-logo-and-tagline-contai3">
                <img
                  className="thinklab-logo-icon7"
                  alt=""
                  src="/thinklab-logo@2x.png"
                />
                <div className="paragraph-block-text13">
                  <div className="newsparagraph-block-text">
                    <p className="crafting-excellence-in3">
                      Crafting Excellence in every blueprint.
                    </p>
                    <p className="crafting-excellence-in3">
                      Trust us to redefine standards and deliver unmatched
                      efficiency across diverse domains.
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-link-white4">
                <img
                  className="twitter-logo-icon4"
                  alt=""
                  src="/twitter-logo1.svg"
                />
                <img
                  className="twitter-logo-icon4"
                  alt=""
                  src="/instagram-logosvg1.svg"
                />
                <img
                  className="twitter-logo-icon4"
                  alt=""
                  src="/facebook-symbolsvg1.svg"
                />
              </div>
            </div>
            <div className="footer-navigation-link3">
              <div className="newsheading-block-text">
                <div className="div5">Our Company</div>
              </div>
              <div className="newsheading-block-text">
                <div className="div5">Our Services</div>
              </div>
              <div className="newsheading-block-text">
                <div className="div5">Our Projects</div>
              </div>
              <div className="newsheading-block-text">
                <div className="div5">{`News & Insights`}</div>
              </div>
              <div className="newsheading-block-text">
                <div className="div5">Careers</div>
              </div>
              <div className="newsheading-block-text">
                <div className="div5">Contact Us</div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved3">
            <div className="copyright-parent1">
              <img className="twitter-logo-icon4" alt="" src="/copyright.svg" />
              <div className="newsheading-block-text">
                <div className="div8">2024</div>
              </div>
            </div>
            <div className="div8">{`Thinklab Group. All Rights Reserved. `}</div>
          </div>
        </div>
      </div>
      {/* {isTabletmobileOverlayMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTabletmobileOverlayMenu}
        >
          <TabletmobileOverlayMenu1 onClose={closeTabletmobileOverlayMenu} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default News;
